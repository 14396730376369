    /*****************************************************************************/
    /*
/* Block items components
/*
/*****************************************************************************/
    
    // The wrapper
    %flg-block-wrapper {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        font-size: .8em;
        margin-top: 1em;
        flex-wrap: wrap;
        @media (min-width: $flg-small) {
            font-size: 1.2em;
        }
    }
    
    @mixin flg-block-wrapper() {
        @extend %flg-block-wrapper;
    }
    
    // The title
    %flg-block-title {
        margin: 1.4em 0 0;
        font-size: 0.8em;
        text-align: center;
        text-transform: uppercase;
        font-weight: 400;
        color: $flg-text-color;
        @media (min-width: $flg-small) {
            font-size: 0.6em;
        }
    }
    
    //The actual border 
    %flg-border-block {
        margin: 0;
        padding: 1em;
        border: $block-border;
        background: #fff;
        border-radius: $box-border-radius;
    }
    
    // The items
    %flg-block {
        margin: 0 auto;
        width: 100%;
        line-height: 1.2em; // transition: transform .2s ease-in;
        .block-title {
            @extend %flg-block-title;
        }
        .border-block {
            @extend %flg-border-block;
        } // &:hover {
        // 	transform: scale(1.05, 1.05);
        // }
    }
    
    %flg-offer-section-title {
        margin: 1.4em 0 0.2em 1.8em;
        font-size: 0.8em;
        text-transform: uppercase;
        font-weight: 400;
        color: $flg-text-color;
        @media (min-width: $flg-small) {
            font-size: 0.6em;
        }
    }
    
    @mixin flg-block() {
        @extend %flg-block;
    }
    
    @mixin flg-offer-option-block() {
        margin: 0;
        padding: .4em 1em;
        border: $block-border;
        background: #fff;
        border-radius: $box-border-radius;
    }
    /*****************************************************************************/
    /*
/* Testimonials
/*
/*****************************************************************************/
    
    .site .content-block.testimonial-divider {
        background-image: url('/assets/images/testimonial_bg_banner.jpg'); // background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.4)), url('/assets/images/testimonial_bg_banner.jpg');
        background-size: cover;
        background-position: center center;
        width: 100%;
        max-width: 100%;
        padding: 1em;
        margin: 2em 0;
        box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.7);
        text-align: center;
        @media (min-width: $flg-medium) {
            padding: 2em;
        }
    }
    
    // .testimonials-wrapper {
    //   @media (min-width: $flg-medium) { 
    //     width: 80%;
    //   }
    // background: #ccc;
    // margin: 0 auto;
    .site .testimonials {
        margin: 0em;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: center;
        @media (min-width: $flg-medium) {
            flex-direction: row;
            align-items: stretch;
        }
        text-align: start;
    }
    
    // }
    .testimonials .refill {
        display: none;
    }
    
    .testimonials.refilled .refill {
        display: inherit;
    }
    
    $testimonials-break-point: $flg-large+1; //iPad 
    .testimonial {
        display: flex;
        padding: 2em;
        background: rgba(255, 255, 255, .88);
        border-radius: $box-border-radius;
        align-items: center;
        flex-direction: column;
        margin: 1em auto;
        max-width: 100%;
        min-width: 15em;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.7); //  border: 2px solid #507997;
        justify-content: space-between;
        @media (min-width: $flg-medium+1) {
            // border: $flg-default-border;
            // background-color: #fff;
            margin: .5em;
            max-width: 44%;
        }
        @media (min-width: $testimonials-break-point) {
            padding: 0em 1em;
            flex-direction: row;
            min-width: 35em;
        }
        @media (min-width: $flg-xxlarge) {
            max-width: 40em;
        }
        .author {
            order: 2;
            @media (min-width: $testimonials-break-point) {
                order: 1;
                margin: 0em;
            }
            .authorpic {
                flex-shrink: 0;
                width: 8em;
                height: 8em;
                border-radius: 4em;
                overflow: hidden;
                margin: -4em auto 0 auto;
                @media (min-width: $flg-small) {
                    width: 10em;
                    height: 10em;
                    border-radius: 5em;
                }
                @media (min-width: $testimonials-break-point) {
                    width: 140px;
                    height: 140px;
                    border-radius: 70px;
                    margin: .5em;
                }
                img {
                    width: 100%;
                }
            }
            .authorname {
                text-align: center;
                margin: 0;
                font-weight: bold;
                display: block;
                font-size: .8em;
                color: $flg-text-color; // @media (min-width: $flg-medium) { 
                //   // &:before { content: ' – '; }
                //   // color: $flg-text-color;
                // }
            }
        }
        blockquote {
            padding: .5em .5em 4em .5em;
            font-size: .9em;
            line-height: 1.4em;
            order: 1; // background: #fff;
            color: $flg-text-color; // border-radius: 2px;
            margin-bottom: 2em; //   font-style: italic;
            @media (min-width: $testimonials-break-point) {
                border: none;
                padding: 2em;
                margin-bottom: 0em; // color: $flg-text-color-on-theme;
                // background-color: $flg-theme-color-flat;
                order: 2;
                font-size: .85em;
            }
            p {
                padding: 0;
                margin: 0;
            }
        }
        .quotationmark {
            font-size: 120px;
            display: none;
        }
    }
    
    // li.testimonial:not(:first-child) {
    //     margin-top: 2em;
    //     @media (min-width: 82em) {
    //       margin-top: 0;
    //     }
    // }
    #show-more-testimonials {
        @extend .get-quote; // text-align: center;
        // margin: 1.5em auto 0;
        // display: inline-block;
        // border: 1px solid $flg-text-color;
        // font-weight: bold;
        // padding: .2em .8em;
        background: rgba(255, 255, 255, .88); // color: $flg-text-color;
        // text-align: center;
        // border-radius: $box-border-radius; //border: 2px solid currentColor;
        // cursor: pointer;
        font-size: .8em; // height: 3em;
        // line-height: 2.9em;
        &.hidden {
            display: none;
        }
    }
    
    // a#show-more-testimonials:after {
    //     content: "6";
    //     display: block;
    //     background: red;
    //     border-radius: 0.8em;
    //     width: 1.6em;
    //     height: 1.6em;
    //     line-height: 1.6em;
    //     font-size: 0.8em;
    //     color: white;
    //     font-weight: bold;
    //     position: relative;
    //     top: -4em;
    //     left: 2.5em;
    // }
    /*****************************************************************************/
    /*
/* Badges
/*
/*****************************************************************************/
    
    .badges {
        // background: #ccc;
        max-width: 100%;
        margin: 1em auto;
        ul {
            text-align: center;
            li {
                display: inline-block;
                max-width: 170px;
                margin: 0 1em;
                a {
                    display: inline-block;
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
    /*****************************************************************************/
    /*
/* Blog Features
/*
/*****************************************************************************/
    
    .blogfeatures {
        .postlist {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            flex-grow: 1;
            width: 100%;
            position: relative;
            margin: .5em auto;
            padding: .5em;
            @media (min-width: $flg-medium) {
                margin: 1em auto 0;
                padding: 0em;
            }
            a {
                // flex: 100%;
                flex: 47%;
                position: relative;
                margin: .5em;
                align-items: center;
                align-self: center;
                background-size: cover;
                background: $flg-light-panel-color;
                border-radius: $box-border-radius;
                background-position: center center;
                @media (min-width: $flg-medium) {
                    margin: .5em;
                    flex: 47%;
                }
                &:hover {
                    background: hsla(240, 5%, 90%, 1);
                }
            }
            .article-info {
                position: relative; // text-align: center;
                display: flex;
                align-items: center; // width: 100%;
                justify-content: flex-start;
                padding: .2em; // font-size: .8em;
                flex-direction: column;
                @media (min-width: $flg-small) {
                    font-size: 1em;
                }
                @media (min-width: $flg-medium) {
                    padding: .5em;
                    flex-direction: row;
                }
                .meta-group {
                    flex-grow: 1;
                }
                h3 {
                    font-family: $flg-title-font;
                    font-weight: normal;
                    font-size: 1.4em;
                    margin: .2em 0;
                    padding: 0;
                    text-align: center;
                    line-height: .9em;
                    color: $flg-text-color;
                }
                .category {
                    text-align: center;
                    width: 100%;
                    display: block;
                    font-size: .8em;
                    font-weight: normal;
                    text-transform: uppercase;
                }
                .img-wrapper {
                    width: 7em;
                    border-radius: 3.5em;
                    overflow: hidden;
                    margin: .8em;
                    flex-shrink: 0;
                    img {
                        width: 100%;
                        display: block;
                    }
                }
            }
        }
    }
    /**

Cookier Banner

**/
    
    $ka-cookie-bg: rgba(34,
    34,
    34,
    .95) !default;
    $ka-cookie-color: #fff !default;
    $ka-cookie-link-color:$flg-theme-color-flat !default;
    $ka-cookie-link-color-hover: #b2f7ff !default;
    $ka-cookie-btn-bg:$flg-theme-color-flat !default;
    $ka-cookie-btn-color: #fff !default;
    $ka-cookie-position: bottom-right !default;
    body {
        #cookie_banner-wrapper .cookie_container {
            width: 100%;
            bottom: 0;
            right: 0;
            left: initial;
            animation-name: none;
            font-size: 1rem;
            @media (min-width: $flg-medium) {
                width: 25%;
                min-width: 25em;
                border-radius: 5px 0 0 0;
            }
            .cookie_message {
                max-width: 60rem;
                margin: 0 auto 1em;
                @media (min-width: $flg-medium) {
                    // max-width: 60%;
                }
            }
            .cookie_btn {
                max-width: 20rem;
                display: block;
                margin: 1rem auto .5rem;
                font-size: 1em;
                max-height: 2rem;
                @media (min-width: $flg-medium) {
                    max-width: 14rem; // margin: 1rem auto .5rem 1rem;
                }
            }
        }
    }
    /*****************/
    /*****  Detail pages articles ****/
    
    .content-img {
        max-width: 100%;
        width: 35em;
    }