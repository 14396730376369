/*****************************************************************************/
/*
/* Common
/*
/*****************************************************************************/

/* Global Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

::selection {
  background: $flg-theme-color-flat; 
  color: $flg-text-color-on-theme;
}

html, body { height: 100%; }

body {
  background-color: $flg-theme-color-flat;
  font-size: 18px;
  font-family: $flg-fonts;
  color: $flg-text-color;
  -webkit-font-smoothing: antialiased;
  min-width: 250px;
  @media (min-width: $flg-small) {
       font-size: 20px;
  }
  @media (min-width: $flg-medium) {
       font-size: 18px;
  }
}
.wrapper {
  background-color: #fff;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%; 
  margin-top: 2em;
}

h1 { 
  margin-bottom: 1em;
  font-size: 1.2em;
  font-weight: normal;
  text-transform: uppercase;
}


h2 {
  font-weight: 400;
  font-size: 1.2em;
}

h3 {
  font-size: 1.2em;
}


p { margin: 1em 0; }


a         { color: $flg-theme-color-text; text-decoration: none;}
a:visited { color: $flg-theme-color-text; }
a:hover   { color: $flg-text-color-dark; }
