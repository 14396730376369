@import "settings";
@import "reset";
@import "components";
@import "./distr/site/plugins/cookie/cookie.scss";
@import './../../bower_components/font-awesome/scss/font-awesome.scss';

/*


General

*/

span.center {
    text-align: center;
}


/*****************************************************************************/


/*
/* Site
/*
/*****************************************************************************/

.site {
    margin: 3em auto;
    line-height: 1.5em;
    ol,
    ul {
        margin-left: 1.5em;
    }
    .banner {
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        display: block;
    }
    .content-block {
        width: 95%;
        margin: 0 auto;
        max-width: 50em;
        padding-left: env(safe-area-inset-left);
        padding-right: env(safe-area-inset-right);
        // @media (min-width: $flg-medium) {
        //     width: 95%;
        // }
    }
}

.wrapper {
    padding: constant(safe-area-inset-top) constant(safe-area-inset-right) constant(safe-area-inset-bottom) constant(safe-area-inset-left);
}


/*****************************************************************************/


/*
/* HEADER
/*
/*****************************************************************************/

.flg-brand.monotype {
    width: 90%;
    max-width: 24em;
    margin: 0 auto 2em;
    text-align: center;
    padding-top: .5em;
    .flg-title-mono img {
        width: 100%;
    }
}


/*****************************************************************************/


/*
/* NAVIGATION
/*
/*****************************************************************************/

nav {
    width: 100%;
    max-width: $flg-large;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 1.5em;
    ul {
        list-style-type: none;
        li {
            font-family: $flg-fonts;
            padding: 0.3em 0;
            font-size: 1.2em;
            font-weight: 300;
            margin: 0 auto;
            display: block;
            @media (min-width: $flg-medium) {
                display: inline;
                margin: 0 0.5em;
            }
            @media (min-width: $flg-medium) {
                margin: 0 .5em;
            }
            a {
                color: $flg-text-color;
                text-decoration: none;
                &:visited {
                    color: $flg-text-color;
                }
                &:active {
                    color: $flg-text-color;
                } // &:hover {text-decoration: underline;}
            }
            &.current a {
                font-weight: bold;
            }
        }
    }
}

.sep {
    width: 50%;
    margin: 2.1em auto;
    height: 1px;
    display: block;
    background: $flg-text-color-super-light;
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
        height: 0.5px;
    }
}

.second-level-nav {
    font-size: .8em;
    ul li {
        a {
            border: 1px solid transparent;
            padding: 0.1em 0.5em;
            font-weight: normal;
        }
        &.current a {
            font-weight: normal;
            border-color: $flg-text-color;
        }
    }
}

// .content-block>h1 {
//     text-align: center;
// }
.content-block>h3 {
    margin: 2em 0em 0.5em;
    font-size: 1.12em;
    font-weight: bold;
}


/*****************************************************************************/


/*
/* Galleries
/*
/*****************************************************************************/

//Galleries
.photos.masonry,
.photos.flickity {
    opacity: 0;
    transition-property: height, opacity;
    &.loaded {
        opacity: 1;
        height: inherit;
        min-height: inherit;
    }
}

.photos.masonry {
    height: 0;
    transition-property: height, opacity;
    &.loaded {
        height: inherit;
    }
}

.photos.masonry .photo {
    display: none;
}

.photos.masonry.loaded .photo {
    display: block;
}

.photos.masonry .photo img {
    transition: opacity .2s ease-in;
}

.flickity-prev-next-button.next {
    margin-right: env(safe-area-inset-right);
}

.flickity-prev-next-button.previous {
    margin-left: env(safe-area-inset-left);
}


/*****************************************************************************/


/*
/* Flex gallery
/*
/*****************************************************************************/

.photos.flgflex {
    width: 100%;
    max-width: 100%; // Fixes a rotation bug on iOS
    margin: 0 auto 1em;
    min-height: 25em;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    $flg-gutter-zero: 0px;
    $flg-gutter-mini: 2px; // $flg-gutter-small: .3%;
    $flg-gutter-medium: 2px;
    $flg-gutter-large: 5px;
    .gutter-sizer {
        width: $flg-gutter-zero;
        @media (min-width: $flg-mini) {
            width: $flg-gutter-mini;
        } // @media (min-width: $flg-small) {width: $flg-gutter-small;}
        @media (min-width: $flg-medium) {
            width: $flg-gutter-medium;
        } // @media (min-width: $flg-large) {width: $flg-gutter-large;}
    }
    .photo {
        margin: $flg-gutter-zero;
        flex: 0 0 auto;
        @media (min-width: $flg-mini) {
            margin: $flg-gutter-mini;
        }
        @media (min-width: $flg-medium) {
            margin: $flg-gutter-medium;
        } // @media (min-width: $flg-large) {margin-bottom: $flg-gutter-large;}
        width: 100%;
        @media (min-width: $flg-mini) {
            width: $flg-mini;
        }
        @media (min-width: $flg-small) {
            width: 280px;
            height: 280px;
        }
        @media (min-width: $flg-medium) {
            width: 254px;
            height: 254px;
        }
        @media (min-width: $flg-large) {
            width: 300px;
            height: 300px;
        }
        img {
            width: 100%;
            display: block;
            object-fit: cover;
            height: 100%;
        }
    }
}


/*****************************************************************************/


/*
/* Masonry gallery
/*
/*****************************************************************************/

.photos.masonry {
    width: 100%;
    max-width: 100%; // Fixes a rotation bug on iOS
    margin: 0 auto 1em;
    min-height: 25em;
    $flg-gutter-zero: 1px;
    $flg-gutter-mini: 2px; // $flg-gutter-small: .3%;
    $flg-gutter-medium: 2px;
    $flg-gutter-large: 5px;
    .gutter-sizer {
        width: $flg-gutter-zero;
        @media (min-width: $flg-mini) {
            width: $flg-gutter-mini;
        } // @media (min-width: $flg-small) {width: $flg-gutter-small;}
        @media (min-width: $flg-medium) {
            width: $flg-gutter-medium;
        } // @media (min-width: $flg-large) {width: $flg-gutter-large;}
    }
    .photo {
        margin-bottom: $flg-gutter-zero;
        @media (min-width: $flg-mini) {
            margin-bottom: $flg-gutter-mini;
        }
        @media (min-width: $flg-medium) {
            margin-bottom: $flg-gutter-medium;
        } // @media (min-width: $flg-large) {margin-bottom: $flg-gutter-large;}
        $photo-tiny-square: 100px;
        $photo-mini-square: 120px;
        $photo-small-square: 150px;
        $photo-medium-width: 254px;
        $photo-large-width: 320px; // width: 100%;
        width: $photo-tiny-square;
        height: $photo-tiny-square;
        @media (min-width: $flg-mini) {
            width: $photo-mini-square;
            height: $photo-mini-square;
        }
        @media (min-width: $flg-small) {
            width: $photo-small-square;
            height: $photo-small-square;
        }
        @media (min-width: $flg-medium) {
            width: $photo-medium-width;
            height: auto;
            &.orientation-p {
                height: $photo-medium-width;
            }
        }
        @media (min-width: $flg-large) {
            width: $photo-large-width;
            height: auto;
            &.orientation-p {
                height: $photo-large-width;
            }
        }
        @media (min-width: $flg-xxlarge) {
            width: 100%;
            max-width: 420px;
            height: auto;
            &.orientation-p {
                height: auto;
            }
        }
        img {
            width: 100%;
            display: block;
            object-fit: cover;
            height: 100%;
        }
    }
}


/*****************************************************************************/


/*
/* Flickity Gallery
/*
/*****************************************************************************/

$flg-flickity-height-large: 450px;
$flg-flickity-height-medium: 350px;
$flg-flickity-height-small: 300px;
.photos.flickity {
    overflow: hidden; // a {
    //   cursor: -webkit-grab;
    //   &:active {
    //     cursor: -webkit-grabbing;
    //   }
    // }
}

// .photos.flickity .photo {
//   width: 100%;
//   img {
//     width: 100%;
//   }
// }
//Height
.photos.flickity,
.photos.flickity .photo img {
    height: $flg-flickity-height-small;
    @media (min-width: $flg-medium) {
        height: $flg-flickity-height-medium;
    }
    @media (min-width: $flg-large) {
        height: $flg-flickity-height-large;
    }
}

.flickity-zoom {
    display: block;
    margin: 1em auto;
    border: none;
    background: rgba(0, 0, 0, 0);
    padding: .2em;
    cursor: pointer;
    color: $flg-text-color;
}


/*****************************************************************************/


/*
/* Offers
/*
/*****************************************************************************/

$offer-text-color: $flg-text-color;
$offer-background: #fff;
$recommended-text-color: $offer-text-color;
$recommended-background:$offer-background;

/****************/

// $offer-text-color: $flg-text-color;
// $offer-background: #e9f4fd;
// $recommended-text-color: $offer-text-color;
// $recommended-background: #cae3fa; //$offer-background; // $flg-theme-color-flat;

/****************/

$offers-border: 1px solid $flg-text-color;

/****************/

.site .content-block.offers-table {
    @media (min-width: $flg-medium) {
        max-width: 58em;
    }
    @media (min-width: $flg-xlarge) {
        max-width: 70em;
    }
    &>h3 {
        margin: 4em auto 2em;
    }
}

.offers {
    @include flg-block-wrapper(); // background: rgba(0, 0, 0, .1);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    justify-items: start;
    flex-wrap: wrap;
    font-size: 1rem;
    @media (min-width: $flg-medium) {
        width: 100%;
        margin: 0 auto;
    }
    @media (min-width: $flg-xlarge) {
        flex-wrap: nowrap;
    }
}

.offer,
.option {
    @include flg-block();
    cursor: default;
}

.offer {
    flex: 100% 1 0; // border-width: 0px;
    margin: 0 0 2em 0;
    // border-width: 1px;
    .offer-title {
        text-align: center;
        margin: 0;
        font-size: .9em;
    } // @media (max-width: $flg-medium) {
    //     margin-bottom: 2em;
    // }
    @media (min-width: $flg-medium) {
        flex: 35% 0 0;
        margin: 0 1em 2em 0;
    }
    @media (min-width: $flg-xlarge) {
        flex: 35% 1 .5;
    }
    .feature-list {
        border-width: 2px;
        color: $offer-text-color;
        background: $offer-background; // border-width: 0;
    }
    .intro {
        background: #e7f0f8;
        border-radius: .1em;
        padding: 1em;
        margin: 0em auto 1em;
        text-align: center;
    }
}

.recommended {
    @media (min-width: $flg-medium) {
        flex: 50% 1 0;
    }
    @media (min-width: $flg-xlarge) {
        flex: 50% 1 .5;
    }
    @media (min-width: $flg-large) {
        //  max-width: 27em;
    }
    .offer-title {
        font-weight: bold;
    }
    .feature-list {
        // border-width: 4px;
        background: $recommended-background;
        color: $recommended-text-color;
        .feature .description {
            color: $recommended-text-color;
        }
    }
}

.offer-title,
.options-wrapper .option-title {
    @extend %flg-offer-section-title;
    margin: 0;
    font-size: .75em;
}

ul.feature-list {
    @include flg-offer-option-block();
    list-style-type: none;
    margin: 0 0 .8em;
}

.offer .feature-list {
    padding: 1em;
}

.feature {
    margin: 0 auto 1.2em;
    line-height: 1.4em; // min-height: 2em;
    @media (min-width: $flg-medium) {
        // &:nth-child(2) {
        min-height: 3em; // }
    }
    .title {
        margin: 0 auto;
        font-weight: bold;
        @media (min-width: $flg-medium) {
            display: block; // font-size: .8em;
        } // &:before {
        //     content: '✔';
        //     content: '';
        //     font-family: 'FontAwesome';
        //     margin-right: .4em;
        // }
    }
    .description {
        color: $flg-text-color-light;
        margin: 0; // font-weight: 400;
        font-size: 1em;
        line-height: 1.4em;
        @media (min-width: $flg-medium) {
            // font-size: .7em;
            display: block;
        }
    } // &.addition {
    //     .title {
    //         &:before {
    //                 content: '';
    //         }
    //     }
    // }
}

// .option .feature .title:before {
//     content: none;
//} // .offer.custom .feature .title:hover:before {  content: '◎';}
// .option .feature:hover .title:before {
//     content: none;
// }
.options-wrapper {
    // background: rgba(0, 0, 0, .3);
    display: flex;
    flex: 100% 1 1;
    flex-wrap: wrap;
    flex-direction: row;
    @media (min-width: $flg-medium) {
        // width: 66%;
        flex: 35% 1 1;
        margin: 2em auto 0;
    }
    @media (min-width: $flg-xlarge) {
        margin: 0;
    }
}

.option {
    flex: 40% 1 1;
    &:first-child {
        margin-right: 1em;
    }
    @media (min-width: $flg-medium) {
        flex: 30% 1 1;
        margin: 0 1em 2em 0;
    }
    @media (min-width: $flg-xlarge) {
        flex: 55% 1 1;
        margin: 0 1em 2em 0;
    }
    .feature-list {
        border-style: dashed;
        border-width: 2px;
        font-size: .85em;
        padding: .8em 1.0em;
        .feature {
            margin-bottom: 0;
            padding: 0;
            @media (min-width: $flg-xlarge) {
                min-width: 10em;
            }
            .title {
                // line-height: 1em;
                font-weight: bold;
            }
            .description {
                display: block;
            }
        }
    }
} // Get quote button
$quote-text-color: $flg-text-color;
.get-quote {
    &:visited {
        color: $quote-text-color;
    }
    &:active {
        color: $quote-text-color;
    }
    &:hover {
        box-shadow: 0 2px 5px 0px rgba(119, 119, 119, 0.69);
        background-color: $flg-light-panel-color;
    }
    transition: all .2s ease-out;
    color: $quote-text-color;
    border: 2px solid $flg-theme-color-flat;
    border-radius: $box-border-radius;
    width: 100%;
    margin: 0 auto;
    font-weight: bold; // font-size: 1em;
    text-align: center;
    display: block;
    margin: 2em auto;
    background-color: #fff;
    padding: .5em;
    max-width: 16em;
}

.c2a-banner {
    padding: 3em 0;
    background: $flg-theme-color-flat;
    margin: 2em 0;
    .get-quote {
        margin: 0 auto;
        border-color: $flg-theme-color-text;
    }
    &:hover .get-quote {
        transform: scale(1.05);
    }
}

.smallprint {
    font-size: .7em;
    font-weight: bold;
    margin-top: 8em;
    text-align: center;
}


/*****************************************************************************/


/*
/* Section links
/*
/*****************************************************************************/

.section-links {
    @include flg-block-wrapper();
}

.section-link {
    @include flg-block;
    max-width: 300px;
    margin: 0 auto;
    @media (min-width: $flg-small) {
        width: 45%;
    }
    .title {
        @extend .block-title;
    }
    .wrapper {
        @extend .border-block;
        img {
            width: 100%;
            display: block;
            margin: 0 auto;
        }
    }
}


/*****************************************************************************/


/*
/* More photos links
/*
/*****************************************************************************/

.content-block.more-photos {
    &.hidden {
        display: none;
    }
    text-align: center;
    a {
        @extend .get-quote;
        max-width: 30em;
        display: inline-block;
        padding: 1.4em;
        width: auto;
        max-width: 100%
    }
}


/*****************************************************************************/


/*
/* Packages
/*
/*****************************************************************************/

.packages-wrapper {
    width: 100%; // max-width: 500px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @media (min-width: $flg-medium) {
        max-width: 50em;
    }
}

.package {
    @include flg-block;
    width: 100%;
    margin: 1em 0;
    background: #ccc;
    background-size: cover;
    background-position: center center;
    padding: 12em 0 0 0;
    flex: 1 0 18em;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: .2em;
    overflow: hidden;
    @media (min-width: $flg-small) {
        min-width: 22em;
        margin: 5px; // &.full {
        //     //   flex-basis: 25em;
        // }
    }
    .title-image {
        width: 100%;
        overflow: hidden;
        img {
            width: 100%;
        }
    }
    .title {
        margin: 0.3em 0;
        font-size: 1.6em;
        font-weight: normal;
        font-family: $flg-title-font;
        line-height: 1.4em;
    }
    .subtitle {
        margin: 0;
        font-size: .8em;
    }
    .text-container {
        background: rgba(7, 7, 7, 0.62);
        padding: 1em 1em 0 1em;
        color: #fff;
    }
    ul.numbers {
        display: flex;
        background: rgba(7, 7, 7, 0.62);
        color: #fff;
        padding: 0 1em 1em;
        margin: 0;
        font-size: .85em;
        justify-content: flex-end;
    }
    li.numbers-group {
        list-style: none;
        padding: 0 .5em; // flex: 1;
        display: flex;
        align-items: baseline;
        margin: .2em;
        flex-wrap: wrap;
        p {
            padding: 0;
            margin: 0;
        }
        .number {
            font-size: 1.8em;
            margin-right: 0.1em;
        }
        .unit {
            font-variant: small-caps;
            margin: 0; // flex: 1;
            align-self: bottom;
            white-space: nowrap;
            font-size: .9em;
        }
    }
}


/*****************************************************************************/


/*
/* Footer Nav
/*
/*****************************************************************************/

.default .footer-nav {
    display: none;
}

.footer-nav {
    margin-top: 1em;
    transition: all .4s ease-out;
    opacity: 1;
    @media (min-width: $flg-medium) {
        // display: none;
    }
}

.loading .footer-nav {
    opacity: 0;
}

.footer-nav nav.main-nav {
    margin: 2.5em auto 1em;
}


/*****************************************************************************/


/*
/* Footer
/*
/*****************************************************************************/

.footer {
    padding: 1em 0 12em;
    text-align: center;
    background: $flg-theme-color-flat;
    a {
        text-decoration: none;
        color: $flg-text-color-on-theme;
        &:hover {
            color: $flg-text-color-dark;
        }
    }
    ul {
        list-style-type: none;
        li {
            display: inline-block;
        }
    }
    .legal-links,
    .social {
        display: block;
        margin: 1em auto;
        width: 80%;
    }
    .social {
        li {
            margin: 0 .5em;
            a {
                font-size: 2em;
            }
            a i {
                transition: all .2s ease-in-out;
            }
            a:hover i {
                transform: scale(1.5, 1.5);
                color: $flg-text-color-on-theme;
            }
        }
    }
    .legal-links {
        li {
            margin: .7em 1em;
            a {
                font-size: 1em;
                text-decoration: underline;
            }
        }
    }
    .footnote {
        font-size: .9em; // font-weight: bold;
        color: $flg-text-color-on-theme;
    }
}


/*****************************************************************************/


/*
/* Legal sites
/*
/*****************************************************************************/

.legal .site {
    font-size: .8em;
}


/*****************************************************************************/


/*
/* VCard
/*
/*****************************************************************************/

.vcard {
    margin-top: 1.4em;
    .label {
        font-weight: bold;
        a {
            font-weight: normal;
        }
    }
}


/*****************************************************************************/


/*
/* About me
/*
/*****************************************************************************/

.profile-picture {
    margin: 0 auto;
    max-width: 250px;
    border-radius: 50%;
    overflow: hidden;
    img {
        width: 100%;
        display: block;
    }
}


/*****************************************************************************/


/*
/* Contact 
/*
/*****************************************************************************/

.contact-links-wrapper {
    @include flg-block-wrapper();
    .contact-link {
        @include flg-block();
        min-width: 150px;
        width: 100%;
        cursor: pointer;
        text-align: center;
        color: $flg-text-color;
        flex-grow: 1;
        flex-shrink: 1;
        margin: 0 .5em;
        &:hover {
            color: $flg-theme-color-text;
        }
        @media (min-width: $flg-small) {
            width: 30%;
        }
        .border-block {
            @media (min-width: $flg-small) {
                font-size: .6em;
            }
            @media (min-width: $flg-medium) {
                font-size: .8em;
            }
            i {
                margin: .2em auto;
                font-size: 3.5em;
                display: block;
            }
        }
    }
} // @import "debug";